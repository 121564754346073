import React from 'react';
import { Link } from "react-router-dom";
import { UserContext } from '../Contexts/UserContext';
import { loadDataIfNeeded } from '../Utils/loadDataIfNeeded.utils';
import front from '../Utils/front.utils';
import Fade from 'react-bootstrap/Fade';
import Carousel from './Components/Carousel';
import Projects from './Projects';

export default class Home extends React.Component {
  constructor(props) {
    super (props);
    this.state = {
      news: [],
      homes: [],
      projects: [],
      cover: null,
      error: '',
      fading: true,
    }
  }
  
  componentDidMount() {
		document.title = `Thélonious Goupil`;
    loadDataIfNeeded(this, '/homes', { lang: this.context.lang }, 'homes');
    loadDataIfNeeded(this, '/news', { lang: this.context.lang }, 'news');
		loadDataIfNeeded(this, '/projects/full', { lang: this.context.lang }, 'projects');
  }

  render() {
		var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		var h;
		if (w > 1280) {
			h = w * 0.297 + "px";
		} else if (w > 992) {
			h = w * 0.303 + "px";
		} else {
			h = w * 0.6 + "px";   
    }
		
    let img = null;
		let news = null;
		if (this.state.news[0]) {
      if (this.state.homes[0] && this.props.location.pathname !== '/projects') {
        img = this.state.homes[0].cover;
      } else {
        img = this.state.news[0].cover;
      }

			news = <article key={this.state.news[0].id} id={this.state.news[0]['id']} className="w3-row project">
        <Fade
          in={this.props.location.pathname === '/projects'}
        >
          <div>
            <div className="w3-hide-xlarge w3-hide-large project-date">
              <p>NEWS</p>
            </div>
            <div className="w3-hide-xlarge w3-hide-large w3-col m10 project-caption">
              <h1>{this.state.news[0]['name_'+this.context.lang]}</h1>
              <p>
                {front.renderText(this.state.news[0]['caption_'+this.context.lang], this.props.changeColor, this.props.resetColor)}
              </p>
            </div>
          </div>
        </Fade>
        <div className="w3-col m12 l6 project-slideshow">
          <Link
            to={"/projects"}
            className={this.props.location.pathname === '/projects' ? "no-pointer news-link" : "news-link"}
          >
            <div key={img} className="slide">
              <picture style={{height: h}}>
                <source
                  media="(max-width: 767px)"
                  sizes="(max-width: 1440px) 90vw, 1296px"
                  srcSet={`
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_200/' + img}.jpg 200w,
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_1296/' + img}.jpg 1296w`}
                />
                <source
                  media="(min-width: 768px) and (max-width: 991px)"
                  sizes="(max-width: 1440px) 90vw, 1296px"
                  srcSet={`
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_692/' + img}.jpg 692w,
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_1296/' + img}.jpg 1296w`}
                />
                <source
                  media="(min-width: 992px) and (max-width: 1199px)"
                  sizes="(max-width: 2400px) 40vw, 960px"
                  srcSet={`
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_397/' + img}.jpg 397w,
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_960/' + img}.jpg 960w`}
                />
                <img
                  sizes="(max-width: 2592px) 50vw, 1296px"
                  srcSet={`
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_600/' + img}.jpg 600w,
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_1296/' + img}.jpg 1296w`}
                  src={`${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_1296/' + img}.jpg`}
                  className="news-image w3-image"
                  alt={img}
                />
              </picture>
            </div>
          </Link>
        </div>
        <Fade 
          mountOnEnter={true}
          unmountOnExit={true} 
          in={this.props.location.pathname === '/projects'}
        >
          <div>
            <div className="w3-hide-medium w3-hide-small w3-col s10 l2 project-caption">
              <h1>{this.state.news[0]['name_'+this.context.lang]}</h1>
              <p>{front.renderText(this.state.news[0]['caption_'+this.context.lang], this.props.changeColor, this.props.resetColor)}</p>
            </div>
            <div className="w3-hide-medium w3-hide-small w3-col s2 l1 project-date">
              <p>NEWS</p>
            </div>
          </div>
        </Fade>
      </article>

    } else if (Array.isArray(this.state.projects) && this.state.projects.length) {

      const project = front.orderByDate(this.state.projects)[0];
      let cover = null;
      if (this.props.location.pathname !== '/projects') {
        img = this.state.homes[0] ? this.state.homes[0].cover : project.images[0].file;
        cover = (
          <Link
            to={"/projects"}
            className={this.props.location.pathname === '/projects' ? "no-pointer news-link" : "news-link"}
          >
            <div key={img} className="slide">
              <picture style={{height: h}}>
                <source
                  media="(max-width: 767px)"
                  sizes="(max-width: 1440px) 90vw, 1296px"
                  srcSet={`
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_200/' + img}.jpg 200w,
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_1296/' + img}.jpg 1296w`}
                />
                <source
                  media="(min-width: 768px) and (max-width: 991px)"
                  sizes="(max-width: 1440px) 90vw, 1296px"
                  srcSet={`
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_692/' + img}.jpg 692w,
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_1296/' + img}.jpg 1296w`}
                />
                <source
                  media="(min-width: 992px) and (max-width: 1199px)"
                  sizes="(max-width: 2400px) 40vw, 960px"
                  srcSet={`
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_397/' + img}.jpg 397w,
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_960/' + img}.jpg 960w`}
                />
                <img
                  sizes="(max-width: 2592px) 50vw, 1296px"
                  srcSet={`
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_600/' + img}.jpg 600w,
                  ${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_1296/' + img}.jpg 1296w`}
                  src={`${process.env.REACT_APP_S3_BUCKET_BASE_URL + 'w_1296/' + img}.jpg`}
                  className="news-image w3-image"
                  alt={img}
                />
              </picture>
            </div>
          </Link>
        )
      } else {
        cover = (
          <Carousel
            images = {project.images}
            description = {project['description_'+this.context.lang]}
            {...this.props}
          />
        )
      }

      var ratio;
      if (w > 1280) {
        ratio = 94;
      } else if (w > 992) {
        ratio = 80;
      } else {
        ratio = 90;
      }

      let height = (this.state.projects.length)*ratio+5+'%';
      let minHeight = '';
      if (0 === this.state.projects.length - 1) {
        let footerHeight = 124;
        if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1280) {
          footerHeight = 193+45;
        }
        minHeight = `calc(100vh - ${footerHeight}px)`;
      }

      news = <article key={project.id} style={{minHeight}} id={project['id']} className="w3-row project">
        <Fade
          in={this.props.location.pathname === '/projects'}
        >
          <div>
            <div style={{ height }} className={`w3-hide-xlarge w3-hide-large project-date`}>
              <p>{new Date(project.date).getFullYear()}</p> 
            </div>
            <div className="w3-hide-xlarge w3-hide-large w3-col m10 project-caption">
              <h1>{project['name_'+this.context.lang]}</h1>
              <p>{front.renderText(project['caption_'+this.context.lang], this.props.changeColor, this.props.resetColor)}</p>
            </div>
          </div>
        </Fade>
        <div className="w3-col m12 l6 project-slideshow">
          {cover}
        </div>
        <Fade 
          mountOnEnter={true}
          unmountOnExit={true} 
          in={this.props.location.pathname === '/projects'}
        >
          <div>
            <div className="w3-hide-medium w3-hide-small w3-col s10 l2 project-caption">
              <h1>{project['name_'+this.context.lang]}</h1>
              <p>{front.renderText(project['caption_'+this.context.lang], this.props.changeColor, this.props.resetColor)}</p>
            </div>
            <div style={{ height }} className={`w3-hide-medium w3-hide-small w3-col s2 l1 project-date`}>
              <p>{new Date(project.date).getFullYear()}</p>
            </div>
          </div>
        </Fade>
      </article>
    }
    
    return <main id="Projects">
      <section className="news">
        {news}
      </section>
      <Projects
        projects={this.state.projects}
        gotNews={!!this.state.news[0]}
        changeColor={this.changeColor}
        resetColor={this.resetColor}
        {...this.props}
      />
    </main>
  }
}

Home.contextType = UserContext;
